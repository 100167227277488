<template>
  <div>

    <input type='hidden' :name="name" :value="convertedSelected">

    <multiselect v-model="selected" id="ajax" label="name" track-by="id" :placeholder="'Search in ' + asset_layout_name" open-direction="bottom" :options="results" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="30" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind">

      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="selected.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
      </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
    </multiselect>

    <!-- <multiselect :options="options"
                  label="name" track-by="id" :multiple='true' :closeOnSelect='false' v-model='selected'  :placeholder="'Search in ' + asset_layout_name"></multiselect> -->
    <label style='margin-top: .5rem;display: flex;align-items: center;' class="checkbox"><input v-model="global" type="checkbox"/>Global Search in {{asset_layout_name}}</label>
  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'
import Multiselect from 'vue-multiselect'
export default {
  props: ["name", "link_name", "linkable_id", "company", "existing", "options", "asset_layout_name"],
  mounted () {
    var that = this;
  },
  components: {
    Icon,
    Multiselect
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    remove (index) {
      this.selected.splice(index, 1)
    },
    add (id, url, name) {
      this.showSearch = false;
      this.selected.push({id: id, url: url, name: name})
    },
    clearAll () {
      this.results = []
    },
    asyncFind (query) {
      var that = this;
      that.isLoading = true
      that.$api.get('/searches', {
        params: {
          q: query,
          type: "Asset",
          company: that.global ? null : that.company,
          asset_type: that.linkable_id
        }
      })
      .then(function (response) {
        that.results = response.data;
        that.isLoading = false
      })
    }
  },
  computed: {
    convertedSelected: function () {
      return JSON.stringify(this.selected)
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      global: false,
      showSearch: false,
      selected: this.existing,
      isLoading: false
    }
  }
}
</script>

