<template>
  <div>

    <div class='form-group'>
      <label for='name' class='required'>Label</label>
      <input type='text' autofocus v-model='label'>
    </div>

    <input type='hidden' name='asset_layout_field[label]' :value='label'>
    <input type='hidden' name='asset_layout_field[position]' :value='position'>
    <input type='hidden' name='asset_layout_field[field_type]' :value='type'>
    <input type='hidden' name='asset_layout_field[hint]' :value='hint'>
    <input type='hidden' name='asset_layout_field[position]' :value='position'>
    <input type='hidden' name='asset_layout_field[options]' :value='options'>
    <input type='hidden' name='asset_layout_field[max]' :value='max'>
    <input type='hidden' name='asset_layout_field[min]' :value='min'>
    <input type='hidden' name='asset_layout_field[expiration]' :value='expiration'>
    <input type='hidden' name='asset_layout_field[required]' :value='required'>
    <input type='hidden' name='asset_layout_field[linkable_id]' :value='linkable_id'>
    <input type='hidden' name='asset_layout_field[column_width]' :value='column_width'>
    <input type='hidden' name='asset_layout_field[linkable_type]' :value='linkable_type'>
    <input type='hidden' name='asset_layout_field[show_in_list]' :value='show_in_list'>
    <input type='hidden' name='asset_layout_field[multiple_options]' :value='multiple_options'>
    <input type='hidden' name='asset_layout_field[list_id]' :value='list_id'>

    <p class='asset-types__types-explainer' v-if='type == "Text"'>Basic text</p>
    <p class='asset-types__types-explainer' v-if='type == "RichText"'>Store long, formatted text</p>
    <p class='asset-types__types-explainer' v-if='type == "Heading"'>Display a header to separate info</p>
    <p class='asset-types__types-explainer' v-if='type == "CheckBox"'>For boolean info: yes/no, on/off, etc.</p>
    <p class='asset-types__types-explainer' v-if='type == "Website"'>Link to external websites and IP addresses</p>
    <p class='asset-types__types-explainer' v-if='type == "Password"'>Store confidential information that is hidden by default and audited. <b>Not available</b> in password lists</p>
    <p class='asset-types__types-explainer' v-if='type == "Number"'>Store numerical info</p>
    <p class='asset-types__types-explainer' v-if='type == "Date"'>Display a date calendar. Optionally, add items to expirations</p>
    <p class='asset-types__types-explainer' v-if='type == "ListSelect"'>Display a dropdown with options from a list</p>
    <p class='asset-types__types-explainer' v-if='type == "Dropdown"'><span class='danger'>DO NOT USE.</span> This type is only kept for compatibility purposes. Use the new List Select instead.</p>
    <p class='asset-types__types-explainer' v-if='type == "Embed"'>Store custom HTML (Youtube videos, LucidCharts, etc.)</p>
    <p class='asset-types__types-explainer' v-if='type == "Email"'>Store basic text that can also be copied to the clipboard</p>
    <p class='asset-types__types-explainer' v-if='type == "Phone"'>Store phone numbers</p>
    <p class='asset-types__types-explainer' v-if='type == "AssetTag"'>Link to other assets from an asset type</p>
    <p class='asset-types__types-explainer' v-if='type == "AssetLink"'><span class='danger'>DO NOT USE.</span>This type is only kept for compatibility purposes. Use the new Asset Link instead.</p>
    <div class='asset-types__types'>
      <label :class='{"selected": type == "Text"}'>
        <i class="fas fa-pencil-alt"></i>Text<input type='radio' value='Text' v-model='type'>
      </label>
      <label :class='{"selected": type == "RichText"}'>
        <i class="fas fa-pen-fancy"></i>Rich Text<input type='radio' value='RichText' v-model='type'>
      </label>
      <label :class='{"selected": type == "Heading"}'>
        <i class="fas fa-heading"></i>Heading<input type='radio' value='Heading' v-model='type'>
      </label>
      <label :class='{"selected": type == "CheckBox"}'>
        <i class="fas fa-check"></i>Check Box<input type='radio' value='CheckBox' v-model='type'>
      </label>
      <label :class='{"selected": type == "Website"}'>
        <i class="fas fa-link"></i>Link<input type='radio' value='Website' v-model='type'>
      </label>
      <label :class='{"selected": type == "Password"}'>
        <i class="fas fa-eye-slash"></i>Confidential Text<input value='Password' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Number"}'>
        <i class="fas fa-sort-numeric-up"></i>Number<input value='Number' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Date"}'>
        <i class="fas fa-calendar-alt"></i>Date<input value='Date' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "ListSelect"}'>
        <i class="fas fa-list-radio"></i>List Select<input value='ListSelect' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Embed"}'>
        <i class="fas fa-code"></i>Embed<input value='Embed' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Email"}'>
        <i class="fas fa-copy"></i>Copyable Text<input value='Email' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Phone"}'>
        <i class="fas fa-phone"></i>Phone<input value='Phone' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "AssetTag"}'>
        <i class="fas fa-tags"></i>Asset Link<input value='AssetTag' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "Dropdown"}'>
        <i class="fas fa-list"></i>Dropdown - Legacy<input disabled value='Dropdown' type='radio' v-model='type'>
      </label>
      <label :class='{"selected": type == "AssetLink"}'>
        <i class="fas fa-tag"></i>Asset Link - Legacy<input disabled value='AssetLink' type='radio' v-model='type'>
      </label>
    </div>

    <div class='form-group' v-show='type == "ListSelect"'>
      <label for='list_id'>List To Pick From:</label>
      <select id='pullfrom' v-model='list_id'>
        <option v-for="l in lists" :value="l.id">{{l.name}}</option>
      </select>
      <span class='hint'>Lists need to be created and modified in Admin > Lists</span>
    </div>

    <div class='form-group' v-show='type == "ListSelect"'>
      <label for='multiple_options'>Allow User To Choose Multiple Options</label>
      <input type='checkbox' id='multiple_options' v-model='multiple_options'>
    </div>

    <div class='form-group' v-show='type != "Heading"'>
      <label for='show_in_list'>Show in list</label>
      <input type='checkbox' id='show_in_list' v-model='show_in_list'>
      <span class='hint'>Show this field in list views</span>
    </div>

    <div class='form-group' v-show='type == "AssetLink" || type == "AssetTag" '>
      <label for="pullfrom" class='required'>Pull From:</label>
      <select id='pullfrom' v-model='linkable_id'>
        <option v-for="at in asset_types" :value="at.id">{{at.name}}</option>
      </select>
    </div>

    <div class='form-group-half' v-show='type == "Number"'>
      <div class='form-group' >
        <label for='min'>Min.</label>
        <input id='min' type='number' placeholder="0" v-model='min'>
      </div>
      <div class='form-group' >
        <label for='max'>Max.</label>
        <input id='max' type='number' placeholder="100" v-model='max'>
      </div>
    </div>

    <div class='form-group' v-show='type != "Heading"'>
      <label for='hint'>Hint</label>
      <input id='hint' type='text' v-model='hint'>
      <span class='hint'>Provide additional information underneath an input. (ex: This is a hint!)</span>
    </div>

    <div class='form-group' v-show='type == "Date"'>
      <label for='expiration'>Add to Expirations</label>
      <input id='expiration' type='checkbox' v-model='expiration'>
    </div>

    <div class='form-group' v-show='type != "Heading" && type != "CheckBox"'>
      <label for='required'>Make Field Required</label>
      <input type='checkbox' id='required' v-model='required'>
    </div>

    <div class='form-group' v-show='type == "Dropdown"'>
      <label for="options" class='required'>Options</label>
      <textarea id="options" v-model='options'></textarea>
      <span class='hint'>One option per line</span>
    </div>

    <div class='form-group' v-show='type != "Heading" && type != "Embed" && type != "RichText" && type != "Password"'>
      <label for="pullfrom" class='required'>Column Width</label>
      <select v-model='column_width'>
        <option value='variable'>Variable</option>
        <option value='full_width'>Full Width</option>
      </select>
      <span class='hint'>How this field's value will be displayed</span>
    </div>
  </div>
</template>

<script>

export default {
  props: ["asset_layout_field", "asset_types", "lists"],
  data: function () {
    return {
      label: this.asset_layout_field.asset_type.label,
      position: this.asset_layout_field.asset_type.position,
      show_in_list: this.asset_layout_field.asset_type.show_in_list,
      type: this.asset_layout_field.asset_type.field_type || "Text",
      hint: this.asset_layout_field.asset_type.hint,
      options: this.asset_layout_field.asset_type.options,
      max: this.asset_layout_field.asset_type.max,
      list_id: this.asset_layout_field.asset_type.list_id,
      multiple_options: this.asset_layout_field.asset_type.multiple_options,
      min: this.asset_layout_field.asset_type.min,
      expiration: this.asset_layout_field.asset_type.expiration,
      required: this.asset_layout_field.asset_type.required,
      linkable_id: this.asset_layout_field.asset_type.linkable_id || this.asset_types[0]["id"],
      linkable_type: this.asset_layout_field.asset_type.linkable_type,
      column_width: this.asset_layout_field.asset_type.column_width || "variable"
    }
  },
  mounted () {
    if (!this.list_id && this.lists[0] && this.lists[0]["id"]) {
      this.list_id = this.lists[0]["id"]
    }
  }
}
</script>

